import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

const SnsLinks = ({isWhite}) => {
  const data = useStaticQuery(graphql`
    query {
      black: file(relativePath: {eq: "insta.png"}) {
          childImageSharp {
            fixed(width: 50) {
              src
              width
            }
          }
        }
      white: file(relativePath: {eq: "insta-white.png"}) {
          childImageSharp {
            fixed(width: 50) {
              src
              width
            }
          }
        }
      }
  `)

  const instaSrc = isWhite ?
     data.white.childImageSharp.fixed.src :
     data.black.childImageSharp.fixed.src

  return (
    <StyledSnsLinks>
      <InstaLink
        href="https://www.instagram.com/yo.okysk/"
        target="_blank"
        isWhite={isWhite}
        logo={instaSrc}
      >
        Instagram
      </InstaLink>
    </StyledSnsLinks>
  )
}

const StyledSnsLinks = styled.nav`
  display: flex;
  position: relative;
`

const InstaLink = styled.a`
  color: ${props => props.isWhite ? "white" : "inherit" };
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 24px;

  &:hover {
    opacity: 0.8;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url(${props => props.logo});
    background-position: center;
    background-size: cover;
  }
`

export default SnsLinks