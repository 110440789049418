import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { device } from "../utils/device"
import SnsLinks from "../components/SnsLinks"

const Footer = () => {
  return (
    <footer css={css`
      height: 96px;
    `}>
      <Wrapper>
        <div css={css`
          margin-right: 12px;
        `}>
          <SnsLinks />
        </div>
        <Copy>
          okysk.com &copy;2020 YUSUKE OIKE
        </Copy>
      </Wrapper>
    </footer>
  )
}


const Wrapper = styled.div`
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  align-items: center;
  height: 100%;
  @media ${device.mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`
const Copy = styled.p`
  font-size: 14px;
`


export default Footer;
