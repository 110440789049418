import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import SnsLinks from "../SnsLinks"

const MobileNavigation = ({open}) => {
  return (
    <StyledNavigation open={open}>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/works/">Works</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      <div css={css`
        margin-top: 32px;
      `}>
        <SnsLinks isWhite={true} />
      </div>
      <div css={css`
        position: absolute;
        bottom: 128px;
      `}>
        <Label>office</Label>
        <Address>
          2-8-8 inari, Shimada-shi, Shizuoka<br /> 427-0038
        </Address>
      </div>
    </StyledNavigation>
  )
}

const StyledNavigation = styled.nav`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${props => props.theme.colors.keyColor};
  height: 100vh;
  width: 80vw;
  z-index: 5;
  transform: translateX(${props => props.open ? '0' : '100'}%);
  transition: transform 0.5s ease;
  padding-left: 32px;
  ul {
    margin-top: 128px;
  }

  li {
    color: ${props => props.theme.colors.whiteColor};
    padding: 16px 0;
    font-size: 2rem;
    a {
      text-transform: capitalize;
      color: inherit;
      text-decoration: none;
    }
  }
`

const Label = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  color: ${props => props.theme.colors.whiteColor};
  margin-bottom: 8px;
`

const Address = styled.p`
  font-size: 12px;
  color: ${props => props.theme.colors.whiteColor};
`

export default MobileNavigation;