const theme = {
  colors: {
    baseFontColor: `#141414`,
    baseGrayColor: `#A6A6A6`,
    keyColor: `#80CCDE`,
    whiteColor: `hsla(0,100%, 100%, 0.9)`
  },
  size: {
    desktop: `1040px`
  }

}

export default theme;