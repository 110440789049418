import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const NavigationItem = ({navItem}) => {
  return (
    <StyledNavigationItem>
      <Link
         to={navItem.path}
      >
        { navItem.text }
      </Link>
    </StyledNavigationItem>
  )
}

const StyledNavigationItem = styled.li`
  display: inline-block;
  font-size: 14px;
  padding: 0 16px;
  text-transform: capitalize;
  a {
    text-decoration: none;
    color: inherit;
  }

`

export default NavigationItem

