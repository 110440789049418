import styled from "@emotion/styled"
import { device } from "../../utils/device"

const StyledHeader = styled.header`
  height: 96px;
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    position: fixed;
    top: 0;
    left: 0;
    height: 120px;
    z-index: 2;
    background-color: hsla(360, 100%, 100%, 0.92);
    width: 100%;
  }
`

export default StyledHeader;