import React from "react"

const MyIcon = ({lineColor, keyColor}) => {
  return (
    <>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px"
        y="0px" viewBox="0 0 208 256" enable-background="new 0 0 208 256">
      <path style={
        {
          transition: `fill 0.7s ease-in-out`
        }
      } fill={keyColor} d="M179.8,228.1H23.9v-5.2h155.9V228.1z M63.6,213.3H33.1v5.2h30.4V213.3z M164.7,213.3h-62v5.2h62V213.3z
        M191.8,232.5h-181v5.2h181V232.5z M201.2,242.1H4.3v5.2h196.9V242.1z M151.9,40.1C130.7,3.9,84.2-8.3,48,12.9S-0.4,80.5,20.7,116.7
        L151.9,40.1z M108.9,69l29.3,49c26.8-16,35.5-50.7,19.6-77.5L108.9,69z M69.9,131.2c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6
        c1.4,0,2.6-1.2,2.6-2.6C72.4,132.4,71.3,131.2,69.9,131.2z M77.4,131.6c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6
        c1.4,0,2.6-1.2,2.6-2.6S78.8,131.6,77.4,131.6z"/>
      <path fill={lineColor} d="M41.8,108.3c-10.2,0-14-10.2-14.1-10.3c-0.3-0.7,0.1-1.5,0.8-1.8c0.7-0.3,1.5,0.1,1.8,0.8l0,0
        c0.2,0.4,3.9,10.3,14.3,8.2c13.8-2.7,29.5-12.9,38.8-26.9c-1-0.7-2-1.6-2.8-2.6c-3.4-4.2-4.4-10.4-2.7-17
        c2.8-11.3,9.2-15.7,9.5-15.8c0,0,0.1-0.1,0.1-0.1c0.9-0.4,2.7-0.9,4.4,0.3c2.7,2,3.4,7.4,1.9,16.5c-0.9,5.9-3.2,11.9-6.5,17.6
        c1.5,0.6,3.2,0.8,5.1,0.7c13.2-0.5,20.3-3.9,23-11c0.8-1.9,1.1-2.8,2.4-2.4c1.3,0.4,1,1.5,0.6,2.9c-2.2,7.8-3,12.8-2.4,14.9
        c0.6,1.8,1.7,3,3.3,3.6c2.8,1,6.6-0.1,9.1-1.7c2.3-1.5,3.3-4.4,3.3-4.5c0.2-0.7,1-1.1,1.7-0.9c0.7,0.2,1.1,1,0.9,1.7
        c-0.1,0.2-1.3,3.9-4.5,5.9c-2.8,1.8-7.5,3.4-11.5,2c-2.4-0.9-4.1-2.7-5-5.3c-0.7-2-0.3-5.4,0.4-8.9c-5.5,5.1-13.9,6.2-21.3,6.5
        c-2.5,0.1-4.7-0.3-6.7-1.1c-2.6,4-5.8,7.8-9.5,11.4c-9,8.6-20.4,14.8-31.2,17C44,108.2,42.9,108.3,41.8,108.3z M88.9,45.2
        c-0.7,0.5-5.9,4.6-8.3,14.2C79.2,65.2,80,70.5,82.8,74c0.6,0.8,1.3,1.4,2.1,2c3.1-5.2,5.3-10.8,6.2-16.8c1.7-11-0.2-13.4-0.8-13.8
        C89.7,45,89.1,45.2,88.9,45.2z M18,115.9c0.6-0.4,0.8-1.3,0.3-1.9c-0.2-0.3-18.4-26.1,0.2-62.2C27.1,35.2,38,22.9,50.8,15
        c9.9-6,21-9.3,33-9.9c21.2-1,42.3,8.3,49.6,17.5c7.8,9.7,9.4,24.7,9.4,24.8c0.1,0.8,0.8,1.3,1.5,1.2c0.8-0.1,1.3-0.8,1.2-1.5
        c-0.1-0.6-1.8-15.9-10-26.3c-3.9-4.9-11.3-9.7-20.3-13.2c-7.1-2.8-18.3-5.9-31.6-5.3C47.5,4.1,26.9,29.5,16.1,50.5
        C5.9,70.1,6.4,86.8,8.6,97.2c2.4,11.4,7.2,18.1,7.4,18.4c0.3,0.4,0.7,0.6,1.1,0.6C17.5,116.2,17.7,116.1,18,115.9z M160.2,99.8
        c0.5-0.5,11.8-13.1,12.5-27.9c0.5-11.2-2.4-19.1-8.6-23.7c-3.8-2.8-10.6-0.2-11.4,0.2c-0.7,0.3-1.1,1.1-0.8,1.8
        c0.3,0.7,1.1,1.1,1.8,0.8c1.6-0.6,6.6-2.1,8.7-0.5c5.4,4,8,11.2,7.5,21.3c-0.6,13.8-11.7,26.1-11.8,26.2c-0.5,0.6-0.5,1.4,0.1,2
        c0.3,0.2,0.6,0.4,0.9,0.4C159.5,100.2,159.9,100.1,160.2,99.8z M79.4,173.3c0.8,0,1.3-0.7,1.3-1.5c0-0.8-0.7-1.4-1.5-1.3
        c0,0-2.7,0.2-5.3,0c-2.2-0.1-4.7-1.2-4.8-2.6c-0.1-0.9,2.7-2.1,5.1-2.6c0.7-0.1,1.2-0.8,1.1-1.5c-0.1-0.7-0.7-1.2-1.5-1.2
        c-1.3,0.1-3.5,0.1-4.2-0.3c-1.7-0.8-2.5-2.1-2.3-2.5c0.6-1.7,2.8-2.5,4.7-2.5c2.8,0,5.8,2,5.8,2c0.6,0.4,1.5,0.3,1.9-0.4
        c0.4-0.6,0.3-1.5-0.4-1.9c-0.1-0.1-3.7-2.5-7.4-2.5c-2.9,0-6.3,1.3-7.3,4.4c-0.7,2.2,1.3,4.6,3.6,5.8c-1.2,0.8-2.1,2-1.9,3.5
        c0.3,3.2,4.1,4.9,7.4,5.1c0.9,0.1,1.9,0.1,2.7,0.1C78.1,173.4,79.3,173.3,79.4,173.3z M83.9,135.8c0.8-0.1,1.3-0.8,1.1-1.6
        c-0.5-2.9-3.5-8.4-11.2-8.4c-9.9,0-10.7,7.4-10.8,7.5c-0.1,0.8,0.5,1.4,1.2,1.5c0.8,0.1,1.4-0.5,1.5-1.2c0-0.2,0.6-5,8-5
        c7.2,0,8.4,5.9,8.5,6.1c0.1,0.7,0.7,1.1,1.4,1.1C83.7,135.8,83.8,135.8,83.9,135.8z M58.2,148.7l3.7-5c0.4-0.5,0.3-1.2-0.2-1.5
        c-0.5-0.4-1.2-0.3-1.5,0.2l-3.7,5c-0.4,0.5-0.3,1.2,0.2,1.5c0.2,0.1,0.4,0.2,0.7,0.2C57.6,149.2,57.9,149,58.2,148.7z M66.1,148
        l2.2-5.6c0.2-0.6-0.1-1.2-0.6-1.4c-0.6-0.2-1.2,0.1-1.4,0.6l-2.2,5.6c-0.2,0.6,0.1,1.2,0.6,1.4c0.1,0.1,0.3,0.1,0.4,0.1
        C65.5,148.7,65.9,148.5,66.1,148z M82.9,147.5c0.5-0.3,0.7-1,0.4-1.5l-1.9-3.5c-0.3-0.5-1-0.7-1.5-0.4c-0.5,0.3-0.7,1-0.4,1.5
        l1.9,3.5c0.2,0.4,0.6,0.6,1,0.6C82.6,147.7,82.7,147.6,82.9,147.5z M91.2,149c0.5-0.4,0.6-1.1,0.2-1.5l-3.2-4.3
        c-0.4-0.5-1.1-0.6-1.5-0.2c-0.5,0.4-0.6,1.1-0.2,1.5l3.2,4.3c0.2,0.3,0.5,0.4,0.9,0.4C90.8,149.2,91,149.1,91.2,149z M70.9,132.7
        c-0.8-0.3-1.7-0.2-2.3,0.4c-0.5,0.5-0.7,1.3-0.5,2c0.2,0.9,1,1.4,1.9,1.5c0.3,0,0.5-0.1,0.8-0.2c0.2,0,0.3,0,0.5-0.1
        c0.8-0.3,1.4-1.1,1.1-2C72.1,133.6,71.6,133,70.9,132.7z M70.2,133.4c-0.1,0-0.2,0-0.3,0C70,133.3,70.1,133.4,70.2,133.4z
        M79.5,133.2c-1.1-0.9-2.5-1.1-3.8-0.4c-0.8,0.4-1,1.5-0.6,2.2c0.6,1.2,1.9,1.9,3.2,1.9c0.8,0,1.7-0.7,1.6-1.6c0-0.2,0-0.3-0.1-0.4
        C80.1,134.3,80,133.6,79.5,133.2z M78.1,133.6C78.1,133.6,78.1,133.6,78.1,133.6C78.1,133.6,78.1,133.6,78.1,133.6
        C78.2,133.6,78.2,133.6,78.1,133.6C78.2,133.6,78.2,133.6,78.1,133.6z M77.3,135.6C77.3,135.6,77.3,135.6,77.3,135.6
        C77.3,135.5,77.3,135.5,77.3,135.6C77.3,135.5,77.3,135.5,77.3,135.6z M58.9,113.7c-3.2-0.3-6.1,1.2-8.6,3.1
        c-1.7,1.3,0.7,3.6,2.3,2.3c0.8-0.6,1.7-1.2,2.7-1.6c-0.2,0.8,0,1.7,0.8,2.4c1.8,1.9,5.9,0.8,7-1.4C64.3,115.8,61.1,114,58.9,113.7z
        M59.3,117.4c0-0.1,0.1-0.2,0.1-0.3c0.2,0,0.3,0.1,0.5,0.1C59.6,117.2,59.4,117.3,59.3,117.4z M100.6,188.9
        c-0.7-0.4-1.5-0.1-1.9,0.6c0,0-2.2,3.6-12.6,5.7c0.8-1.2,1.2-2.5,1-3.5c-0.2-1.1-1-1.7-2.1-1.7c0,0,0,0,0,0c-0.6,0-1.1,0.5-1.1,1.1
        c0,0.6,0.5,1.1,1.1,1.1c0,0.1,0,0.4-0.1,0.8c-0.3,0.8-1,1.8-2.3,2.3c-0.3,0.1-0.5,0.3-0.7,0.6c-0.8,0.1-1.7,0.2-2.6,0.3
        c-0.3-0.8-0.1-1.6,0.2-2.2c0.5-1.2,1.5-1.8,1.8-1.8c0.6,0.1,1.2-0.3,1.3-0.9c0.1-0.6-0.2-1.2-0.8-1.3c-1.4-0.3-3,1-3.8,2.4
        c-0.8,1.3-1.1,2.7-0.8,3.9c-0.9,0.1-1.7,0.1-2.6,0.1c-0.9-1.8-1.1-3.3-0.5-4.1c0.4-0.5,0.9-0.7,1.2-0.6c0.6,0.1,1.2-0.3,1.3-0.9
        c0.1-0.6-0.3-1.2-0.9-1.3c-1.2-0.2-2.6,0.4-3.4,1.5c-0.6,0.8-1.2,2.6-0.1,5.4c-0.4,0-0.8,0-1.2,0c0-0.1-0.1-0.1-0.1-0.2
        c-2.1-2.8-1.8-3.8-1.7-3.9c0.2-0.3,0.9-0.5,1.9-0.6c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-2,0-3.3,0.6-3.8,1.8
        c-0.6,1.2-0.3,2.8,1,4.9c-0.1,0-0.1,0-0.2,0c-0.4-0.5-0.9-1-1.6-1.5c-1.1-0.7-1.6-1.4-1.5-2.1c0.3-1.3,2.2-2.6,2.9-2.7
        c0.6-0.1,1-0.6,1-1.2c-0.1-0.6-0.6-1-1.2-1c-1.4,0.2-4.3,2-4.8,4.4c-0.2,0.7-0.1,2,1,3.2c-12.8-2.9-22.3-11.7-29.3-27
        c-8.1-17.5-1.7-46.8-1.6-47.1c0.2-0.7-0.3-1.5-1-1.6c-0.8-0.2-1.5,0.3-1.6,1c-0.1,0.3-1.7,7.7-2.3,17.4c-0.8,13.1,0.6,23.7,4.1,31.5
        c8,17.4,19.5,27,34.9,29.2c0.3,1.3-0.2,2.5-0.2,2.6c-0.2,0.6,0.1,1.2,0.6,1.4c0.1,0,0.3,0.1,0.4,0.1c0.4,0,0.9-0.3,1-0.7
        c0.3-0.7,0.5-1.9,0.4-3.1c0.3,0,0.6,0.1,1,0.1c0.4,0.9,0.3,1.3,0.3,1.5c-0.1,0.3-0.4,0.4-0.4,0.4c-0.6,0.1-0.9,0.8-0.8,1.3
        c0.1,0.5,0.6,0.8,1.1,0.8c0.1,0,0.2,0,0.3,0c0.1,0,1.4-0.4,1.9-1.8c0.2-0.6,0.2-1.4,0-2.1c0.4,0,0.8,0,1.2,0
        c1.3,2.7,0.9,3.9,0.9,3.9v0c-0.3,0.5,0,1.2,0.5,1.5c0.2,0.1,0.3,0.1,0.5,0.1c0.4,0,0.8-0.2,1-0.6c0.2-0.3,0.8-1.9-0.5-4.9
        c0.8,0,1.5-0.1,2.3-0.1c0.1,0,0.1,0,0.2,0c0.2,0.9-0.3,1.7-0.4,1.8c-0.4,0.5-0.3,1.2,0.2,1.6c0.2,0.2,0.5,0.2,0.7,0.2
        c0.3,0,0.6-0.1,0.9-0.4c0.5-0.6,1.1-2,0.9-3.4c0.2,0,0.5-0.1,0.7-0.1c0.1,1.3,0.4,2.6,0.7,3.6c0.1,0.5,0.6,0.8,1.1,0.8
        c0.1,0,0.2,0,0.3,0c0.6-0.2,0.9-0.8,0.8-1.4c-0.3-1.2-0.5-2.4-0.6-3.3c14.4-2.1,17.4-7,17.7-7.6
        C101.5,190.1,101.2,189.3,100.6,188.9z M104.7,114.4c-3-1.4-6.1-0.1-9.2,0.2c-2.1,0.2-2.1,3.5,0,3.2c1-0.1,2-0.3,3-0.5
        c-0.2,0.9-0.2,1.8,0.4,2.7c1.2,1.8,3.3,2,5.3,1.9c0.1,0,0.1,0,0.2,0c1.1-0.1,2.1-0.5,2.8-1.4C109,118.3,107,115.4,104.7,114.4z
        M94.3,100.4c6.3,2.6,12.5,5.4,18.6,8.4c1.9,0.9,3.5-1.9,1.6-2.8c-6.3-3.1-12.8-6.1-19.4-8.7C93.2,96.5,92.4,99.6,94.3,100.4
        L94.3,100.4z M150,117.1c-0.9-2.2-2.4-2.9-3.5-3.1c-3.6-0.7-7.7,3.2-8.2,3.7c-0.5,0.5-0.5,1.4,0,2c0.5,0.5,1.4,0.5,2,0
        c1.3-1.3,4-3.2,5.7-2.9c0.2,0,0.9,0.2,1.5,1.5c1.3,3,0.2,9.6-3,17.7c-3.1,7.8-6.4,12.8-7.3,13.3c-0.6,0.2-1.4,0.4-1.9,0.1
        c-0.7-0.5-0.9-1.8-0.9-2.2c0-0.7-0.5-1.2-1.2-1.3c-0.7-0.1-1.3,0.3-1.5,0.9c-0.3,0.8-6.8,20.1-8.1,29c-1.2,8-3.3,26.9-3.6,30.3
        c-2.6,2.3-18.1,15.2-35.8,15.2c-14.2,0-22.6-4.7-27.1-8.5c3.5-1.1,7.1-1.9,10.7-2.3c2.1-0.2,2.1-3.5,0-3.2c-4.5,0.4-9,1.5-13.3,3
        c-1.3-1.4-1.8-2.4-1.8-2.4c-0.4-0.7-1.2-0.9-1.9-0.6c-0.7,0.4-0.9,1.2-0.6,1.9c0.1,0.1,0.5,1,1.5,2.2c-5,1.9-9.8,4.3-14.3,6.9
        c-10.9,6.2-20,15.1-28.7,24.1c-1.5,1.5,0.8,3.8,2.3,2.3c8.3-8.6,16.9-17.1,27.3-23.1c4.9-2.8,10.3-5.6,15.8-7.6
        c4.5,4.4,13.7,10.2,30.2,10.2c0,0,0,0,0,0c20.4,0,37.3-15.6,38-16.2c0.2-0.2,0.4-0.5,0.4-0.9c0-0.2,2.4-21.9,3.7-30.7
        c0.9-6.4,4.9-18.9,6.8-25c0.2,0.2,0.4,0.3,0.6,0.5c0.8,0.6,2.2,1,4.4,0.2c2.4-0.9,6.2-8.6,8.1-12.9
        C148.2,134.3,152.5,122.9,150,117.1z M132.6,206.6c25.3,10.5,48.5,25.5,68.6,44.1c1.5,1.4,3.8-0.9,2.3-2.3
        c-20.5-19-44.2-34.2-70-44.9C131.5,202.6,130.7,205.8,132.6,206.6L132.6,206.6z"/>
      </svg>
    </>
  )
}

MyIcon.defaultProps = {
  lineColor: `#3E3A39`,
  keyColor: `#80CCDE`,
}

export default MyIcon