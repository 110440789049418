import React from "react"
import styled from "@emotion/styled"
import NavigationItem from "./NavigationItem"
import { device } from "../../utils/device"
import SnsLinks from "../../components/SnsLinks"

const Navigation = () => {
  const navData = [
    {
      text: 'Works',
      path: `/works/`
    },
    {
      text: 'About',
      path: `/about/`
    },
    {
      text: 'Contact',
      path: `/contact/`
    },
  ]

  return (
    <StyledNavigation>
      <ul>
        { navData.map((navItem, index) => <NavigationItem key={index} navItem={navItem} />) }
      </ul>
      <div style={{marginLeft: "32px"}}>
        <SnsLinks />
      </div>
    </StyledNavigation>
  )
}

const StyledNavigation = styled.nav`
  margin-left: auto;
  display: flex;
  ul {
    display: flex;
  }

  @media ${device.mobile} {
    display: none;
  }
`

export default Navigation;