import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

const Brand = ({siteTitle}) => {
  return (
    <h1
      css={css`
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.1em;
        display: inline-block;
        a {
          color: inherit;
          text-decoration: none;
        }
      `}>
      <Link to="/">
        { siteTitle }
      </Link>
    </h1>
  )
}

export default Brand;