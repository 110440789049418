import React from "react"
import styled from "@emotion/styled"
import { device } from "../../utils/device"

const Humberger = ({setOpen, open}) => {
  return (
    <StyledHumberger onClick={() => setOpen(!open)}>
      <Line open={open} />
      <Line open={open} />
      <Line open={open} />
    </StyledHumberger>
  )
}

const StyledHumberger = styled.div`
  display: none;

  @media ${device.mobile} {
    display: block;
    position: fixed;
    z-index: 100;
    top: 32px;
    right: 32px;
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    top: 45px;
  }
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({open, theme}) => open ? theme.colors.whiteColor : theme.colors.baseFontColor};
  transition: 0.3s ease;

  &:first-child {
    transform-origin: left;
    transform: rotate(${props => props.open ? '45' : '0'}deg);
  }

  &:nth-child(2) {
    transform-origin: left;
    transform: translateX(${props => props.open ? '32' : '0'}px);
    opacity: ${props => props.open ? '0': '1'};
  }


  &:nth-child(3) {
    transform-origin: left;
    transform: rotate(${props => props.open ? '-45' : '0'}deg);
  }
`


export default Humberger