import React, {useState} from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import StyledHeader from "./Header.styled"
import Brand from "./Brand"
import MyIcon from "../MyIcon"
import Navigation from "./Navigation"
import Humberger from "../Humberger"
import MobileNavigation from "../MobileNavigation"
import { device } from "../../utils/device"

const Header = ({siteTitle, breadCrumb}) => {
  const [open, setOpen] = useState(false)

  return (
    <StyledHeader>
      <IconWrapper>
        <MyIcon />
      </IconWrapper>
      <Wrapper>
        {
          breadCrumb ?
          <BreadCrumb>
            <Link to={breadCrumb.to}>{breadCrumb.text}</Link>
          </BreadCrumb>
          : null
        }
        <Brand siteTitle={siteTitle} />
        <Navigation />
      </Wrapper>
      <Humberger
        setOpen={setOpen}
        open={open}
      />
      <MobileNavigation open={open}/>
    </StyledHeader>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  justify-content: center;

  @media ${device.mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`
const IconWrapper = styled.div`
  display: none;
  @media ${device.mobile} {
    position: absolute;
    top: 24px;
    left: 24px;
    display: block;
    width: 64px;
    transform: translateX(-4px);
  }
`
const BreadCrumb = styled.nav`
  margin-top: -3px;
  display: inline-block;
  a {
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    margin-right: 8px;
    color: inherit;
    &::after {
      content: '/';
      padding-left: 8px;
    }
  }
`



export default Header;