import React from "react"
import styled from "@emotion/styled"
import { device } from "../../utils/device"

const Container = ({children}) => {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  padding-left: 100px;
  padding-right: 100px;
  min-height: calc(100vh - 192px);
  position: relative;

  @media ${device.mobile} {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 144px;
  }
`

export default Container